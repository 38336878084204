import { useEffect, useState } from 'react';
import { Button } from '@shopify/polaris';
import * as Redirect from '@shopify/app-bridge/actions/Navigation/Redirect';
import getApp from '../../services/app-bridge.service';

export default function GoBackOrToAppBtn({
  goBackUrlInAdmin,
}: {
  goBackUrlInAdmin: string;
}) {
  const [isTimeoutCompleted, setIsTimeoutCompleted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const app = getApp()!;
      const redirect = Redirect.create(app);
      redirect.dispatch(Redirect.Action.ADMIN_PATH, goBackUrlInAdmin);
      setIsTimeoutCompleted(true);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [goBackUrlInAdmin]);

  if (isTimeoutCompleted) {
    return (
      <Button
        loading={false}
        onClick={() => {
          const app = getApp()!;
          const redirect = Redirect.create(app);
          redirect.dispatch(Redirect.Action.APP, '/');
        }}
        variant='primary'
      >
        Continue to app
      </Button>
    );
  }

  return <Button disabled>Going Back in 5 seconds</Button>;
}
