import * as ToastAction from '@shopify/app-bridge/actions/Toast';
import { useEffect, useState } from 'react';
import getApp from '../../services/app-bridge.service';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';
import GoBackOrToAppBtn from '../components/GoBackOrToAppBtn';

function BulkAction({
  url,
  successMessage,
  goBackUrlInAdmin,
}: {
  successMessage: string;
  goBackUrlInAdmin: string;
  url: string;
}) {
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);
    const ids = pageSearchParams.getAll('ids[]');
    const shop = pageSearchParams.get('shop');

    httpInterceptor
      .get(url, {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          ids,
          shop,
        },
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  if (loading) return <CommonScreen loading />;

  if (err) {
    const app = getApp();
    const toastAction = ToastAction.create(app!, {
      message: 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    return <CommonScreen loading={false} />;
  }

  const app = getApp();
  const toastAction = ToastAction.create(app!, {
    message: successMessage,
    duration: 5000,
  });
  toastAction.dispatch(ToastAction.Action.SHOW);

  return (
    <CommonScreen loading={false}>
      <GoBackOrToAppBtn goBackUrlInAdmin={goBackUrlInAdmin} />
    </CommonScreen>
  );
}

export default BulkAction;
