import {
  Frame,
  SkeletonPage,
  Layout,
  SkeletonBodyText,
  LegacyStack,
} from '@shopify/polaris';
import Loading from '@shopify/app-bridge-react/components/Loading';

function LoadingCom() {
  return (
    <div>
      <SkeletonPage primaryAction>
        <Layout>
          <Frame>
            <Loading />
            <LegacyStack vertical>
              <SkeletonBodyText lines={5} />
            </LegacyStack>
          </Frame>
        </Layout>
      </SkeletonPage>
    </div>
  );
}

export default LoadingCom;
