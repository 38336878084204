import { createRoot } from 'react-dom/client';
import { AppProvider } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import AdminLinksApp from './App';

export default function renderAdminLinksApp() {
  const root = createRoot(document.querySelector('#root #firstEle')!);
  root.render(
    <AppProvider i18n={enTranslations}>
      <AdminLinksApp />
    </AppProvider>,
  );
}
