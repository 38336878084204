// import * as Redirect from '@shopify/app-bridge/actions/Navigation/Redirect';
import reportWebVitals from './reportWebVitals';
import '@shopify/polaris/build/esm/styles.css';
// import getAppBridgeInstance, {
import {
  createAppNavigation,
  initializeAppBridge,
} from './services/app-bridge.service';
import { apiEndpointWithTrailingSlash } from './utils/constant';
import loadCrisp from './services/crisp.service';
import renderMainApp from './renderMainApp';
import renderAdminLinksApp from './adminLinks';

// let promise: any;

// if (window.location.pathname.startsWith('/admin-links')) {
//   promise = import('./adminLinks/index');
// } else {
//   promise = import('./renderMainApp');
// }

const fetchAndRenderMainApp = (isSetupCompleted = true) => {
  // if (isSetupCompleted && window.location.pathname === '/') {
  // Redirect to dashboard page
  // const appBridge = getAppBridgeInstance()!;
  // const redirect = Redirect.create(appBridge);
  // redirect.dispatch(Redirect.Action.APP, '/dashboard');
  // }

  // promise.then(({ default: renderMainApp }: any) => {
  renderMainApp(isSetupCompleted);
  // });
};

const main = () => {
  // @ts-ignore
  if (window.isLoading) {
    setTimeout(main, 300);
    return;
  }
  const { isInstalled, isOldIhUser, isSetupCompleted } = window.initialData;

  if (!isInstalled) {
    if (!isOldIhUser) {
      window.location.href = `${apiEndpointWithTrailingSlash}shopify${window.location.search}`;
      return;
    }
    // old invoice hero customer
    import('./oldIhCustomerWarning/index');
  }

  initializeAppBridge();
  if (isOldIhUser) {
    // old invoice hero customer
    import('./oldIhCustomerWarning/index');
  } else {
    createAppNavigation(isSetupCompleted);
    if (window.location.pathname.startsWith('/admin-links')) {
      // promise.then((m: any) => m.default());
      renderAdminLinksApp();
    } else if (!isSetupCompleted) {
      import('./renderWelcomePage').then(({ default: m }) => {
        m(() => {
          fetchAndRenderMainApp(false);
        });
      });
    } else {
      fetchAndRenderMainApp();
    }
  }

  loadCrisp();
};

main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
