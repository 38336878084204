import { useEffect } from 'react';
import renderMainApp from '../../renderMainApp';

function Home() {
  useEffect(() => {
    renderMainApp();
  }, []);

  return <h1>Loading...</h1>;
}

export default Home;
