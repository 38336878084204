import { lazy, Suspense, useLayoutEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import LoadingCom from '../../components/DashboardLoadingCom';
import PageErrorBoundary from '../../components/PageErrorBoundary';
import { fetchInvoices, fetchSettings } from '../../services/api.service';
import { useSettingsQueryTags } from '../../services/queries.service';
import { initialInvoicesQueryParams } from '../../types/api.types';

export const fetchDashboardPage = () =>
  import(/* webpackPrefetch: true */ './Index');

const DashBoardPage = lazy(fetchDashboardPage);

export default function SuspendedDashboardPage() {
  const queryClient = useQueryClient();

  useLayoutEffect(() => {
    Promise.all([
      queryClient.prefetchQuery(useSettingsQueryTags, () => fetchSettings()),
      queryClient.prefetchQuery(
        ['invoices', initialInvoicesQueryParams],
        ({ signal }) => fetchInvoices(initialInvoicesQueryParams, signal),
      ),
    ]).catch(console.error);
  }, [queryClient]);

  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <DashBoardPage />
      </Suspense>
    </PageErrorBoundary>
  );
}
