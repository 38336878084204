import { lazy, Suspense } from 'react';
import LoadingCom from '../../components/LoadingCom';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const ShippingSettingsPage = lazy(
  () => import(/* webpackPrefetch: true */ './Index'),
);

export default function SuspendedCurrencySettingsPage() {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <ShippingSettingsPage />
      </Suspense>
    </PageErrorBoundary>
  );
}
