import {
  IndexTable,
  SkeletonBodyText,
  Button,
  IndexFilters,
  IndexFiltersMode,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { useEffect, useState } from 'react';
import DashboardPageLayout from '../pages/dashboard/DashboardPageLayout';
import { columnHeadings } from '../pages/dashboard/dashboard.constants';

function DashboardLoadingCom() {
  const [show, setShow] = useState(false);
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Default);
  const rowMarkup = [
    <IndexTable.Row position={0} id=''>
      {[...Array(8)].map(() => (
        <IndexTable.Cell>
          <SkeletonBodyText lines={1} />
        </IndexTable.Cell>
      ))}
      <IndexTable.Cell>
        <Button disabled variant='plain'>
          Actions
        </Button>
      </IndexTable.Cell>
    </IndexTable.Row>,
  ];

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 800);
  }, []);

  if (!show) return null;

  return (
    <DashboardPageLayout>
      <IndexFilters
        filters={[]}
        cancelAction={{
          onAction: () => {},
          disabled: true,
          loading: false,
        }}
        mode={mode}
        setMode={setMode}
        tabs={[]}
        selected={1}
        onQueryChange={() => {}}
        onQueryClear={() => {}}
        onClearAll={() => {}}
      />
      <IndexTable headings={columnHeadings} itemCount={25}>
        {[...Array(10)].map(() => rowMarkup)}
      </IndexTable>
    </DashboardPageLayout>
  );
}

export default DashboardLoadingCom;
