import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Page, Layout, Text, LegacyStack } from '@shopify/polaris';
import ErrorPageImage from './ErrorPageImage';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

const ErrorPage: React.FC = () => (
  <div style={{ paddingTop: '10vw' }}>
    <Page narrowWidth>
      <Layout>
        <Layout.Section>
          <LegacyStack
            vertical
            alignment='center'
            spacing='extraLoose'
            distribution='center'
          >
            <LegacyStack.Item>
              <ErrorPageImage />
            </LegacyStack.Item>
            <LegacyStack
              vertical
              alignment='center'
              distribution='center'
              spacing='extraTight'
            >
              <LegacyStack.Item>
                <Text variant='headingXl' as='h5'>
                  Something went wrong!
                </Text>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <Text variant='bodyLg' as='p'>
                  Please re-open app.
                </Text>
              </LegacyStack.Item>
            </LegacyStack>
          </LegacyStack>
        </Layout.Section>
      </Layout>
    </Page>
  </div>
);

const PageErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => (
  <ErrorBoundary fallback={<ErrorPage />}>{children}</ErrorBoundary>
);

export default PageErrorBoundary;
