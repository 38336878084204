import { useEffect, useState } from 'react';
import * as Redirect from '@shopify/app-bridge/actions/Navigation/Redirect';
import * as ToastAction from '@shopify/app-bridge/actions/Toast';
import { AxiosError } from 'axios';
import { Button } from '@shopify/polaris';
import getApp from '../../services/app-bridge.service';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';
import GoBackOrToAppBtn from '../components/GoBackOrToAppBtn';

function SendInvoiceToCustomerForDraftOrder() {
  const [loading, setLoading] = useState(true);
  const [, setRes] = useState(null);
  const [err, setErr] = useState<null | AxiosError<{ message: string }>>(null);
  const [draftOrderId] = useState<string>(
    () => new URLSearchParams(window.location.search).get('id')!,
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const shop = searchParams.get('shop');

    httpInterceptor
      .get('/api/order/draft/send', {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          id: draftOrderId,
          shop,
        },
      })
      .then(res => {
        setRes(res.data);
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [draftOrderId]);

  if (loading) return <CommonScreen loading />;

  const app = getApp()!;

  if (err && (!err.response || err.response.status !== 400)) {
    const toastAction = ToastAction.create(app, {
      message: 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    return <CommonScreen loading={false} />;
  }

  if (err) {
    const toastAction = ToastAction.create(app, {
      message: err!.response!.data.message,
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);

    return (
      <CommonScreen loading={false}>
        <Button
          onClick={() => {
            const redirect = Redirect.create(app);
            redirect.dispatch(Redirect.Action.APP, '/pricing');
          }}
          variant='primary'
        >
          Upgrade to paid plan
        </Button>
      </CommonScreen>
    );
  }

  const toastAction = ToastAction.create(app, {
    message: 'Mail sent successfully!',
    duration: 5000,
  });
  toastAction.dispatch(ToastAction.Action.SHOW);

  return (
    <CommonScreen loading={false}>
      <GoBackOrToAppBtn goBackUrlInAdmin={`/draft_orders/${draftOrderId}`} />
    </CommonScreen>
  );
}

export default SendInvoiceToCustomerForDraftOrder;
