import { Suspense, lazy } from 'react';
import PageErrorBoundary from '../../components/PageErrorBoundary';
import LoadingCom from '../../components/LoadingCom';

const EditInvoicePage = lazy(() => import('./Index'));

export default function SuspendedEditInvoicePage(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <EditInvoicePage {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}
