import { BadgeProps, IndexFiltersProps } from '@shopify/polaris';
import { IndexTableBaseProps } from '@shopify/polaris/build/ts/src/components/IndexTable/IndexTable';
import formatDate from 'date-fns/format';
import { OrderDataItem } from '../../types/api.types';

export const sortOptions: IndexFiltersProps['sortOptions'] = [
  { label: 'Invoice', value: 'orderNo asc', directionLabel: 'Ascending' },
  { label: 'Invoice', value: 'orderNo desc', directionLabel: 'Descending' },
  {
    label: 'Order',
    value: 'shopifyOrderNo asc',
    directionLabel: 'Ascending',
  },
  {
    label: 'Order',
    value: 'shopifyOrderNo desc',
    directionLabel: 'Descending',
  },
  {
    label: 'Date',
    value: 'invoiceDate asc',
    directionLabel: 'Oldest to newest',
  },
  {
    label: 'Date',
    value: 'invoiceDate desc',
    directionLabel: 'Newest to oldest',
  },
  { label: 'Customer', value: 'customerName asc', directionLabel: 'A-Z' },
  { label: 'Customer', value: 'customerName desc', directionLabel: 'Z-A' },
  { label: 'Total', value: 'total asc', directionLabel: 'Lowest to highest' },
  {
    label: 'Total',
    value: 'total desc',
    directionLabel: 'Highest to lowest',
  },
  { label: 'Payment', value: 'paymentStatus asc', directionLabel: 'A-Z' },
  { label: 'Payment', value: 'paymentStatus desc', directionLabel: 'Z-A' },
  {
    label: 'Fulfillment',
    value: 'fulfillmentStatus asc',
    directionLabel: 'A-Z',
  },
  {
    label: 'Fulfillment',
    value: 'fulfillmentStatus desc',
    directionLabel: 'Z-A',
  },
  {
    label: 'Status',
    value: 'status asc',
    directionLabel: 'A-Z',
  },
  {
    label: 'Status',
    value: 'status desc',
    directionLabel: 'Z-A',
  },
];

export const limitOptions = ['25', '50', '75', '100'].map(option => ({
  label: option,
  value: option,
}));

export const paymentStatusBadgeStatus: Record<
  OrderDataItem['paymentStatus'],
  BadgeProps['tone']
> = {
  paid: undefined,
  authorized: 'attention',
  partially_refunded: undefined,
  refunded: undefined,
  partially_paid: 'warning',
  pending: 'warning',
  voided: 'critical',
};

export const paymentStatusProgressStatus: Record<
  OrderDataItem['paymentStatus'],
  BadgeProps['progress']
> = {
  paid: 'complete',
  authorized: 'incomplete',
  partially_refunded: 'partiallyComplete',
  refunded: 'complete',
  partially_paid: 'partiallyComplete',
  pending: 'incomplete',
  voided: 'complete',
};

export const fulfillmentStatusProgressStatus: Record<
  OrderDataItem['fulfillmentStatus'],
  BadgeProps['progress']
> = {
  unfulfilled: 'incomplete',
  fulfilled: 'complete',
  partial: 'partiallyComplete',
  restocked: 'partiallyComplete',
};

export const fulfillmentStatusBadgeStatus: Record<
  OrderDataItem['fulfillmentStatus'],
  BadgeProps['tone']
> = {
  unfulfilled: 'attention',
  fulfilled: 'info',
  partial: 'warning',
  restocked: 'info',
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
] as const;

export const resourceName = {
  singular: 'order',
  plural: 'orders',
};

export const columnHeadings: IndexTableBaseProps['headings'] = [
  { title: 'Invoice' },
  { title: 'Order' },
  { title: 'Date' },
  { title: 'Name' },
  { title: 'Total', alignment: 'end' },
  { title: 'Payment' },
  { title: 'Fulfillment' },
  { title: 'Status' },
  { title: '' },
];

export const getInvoiceDate = (date: string) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const dateNew = `${day < 10 ? `0${day}` : day} ${monthNames[month]} ${year}`;
  return dateNew.toString();
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const dateFilterFormat = 'd MMM yyyy';

export const disambiguateLabel = (
  key: string,
  value: string | string[],
): string => {
  switch (key) {
    case 'paymentStatus':
      return `Payment: ${(value as string[])
        .map(val => capitalizeFirstLetter(val.split('_').join(' ')))
        .join(', ')}`;
    case 'fulfillmentStatus':
      return `Fulfillment: ${(value as string[])
        .map(val => {
          if (val === 'null') return 'Unfulfilled';
          return capitalizeFirstLetter(val.split('_').join(' '));
        })
        .join(', ')}`;
    case 'mailStatus':
      return `Mail: ${value as string}`;
    case 'orderStatus':
      return `Order: ${value as string}`;
    default:
      return value as string;
  }
};

export const disambiguateLabelForDateRange = (start: any, end: any) => {
  let label = 'Order date: ';
  if (start && end) {
    label += `${formatDate(new Date(start), dateFilterFormat)} - ${formatDate(
      new Date(end),
      dateFilterFormat,
    )}`;
  } else if (start) {
    label += `Starting ${formatDate(new Date(start), dateFilterFormat)}`;
  } else if (end) {
    label += `Ending ${formatDate(new Date(end), dateFilterFormat)}`;
  }
  return label;
};

export const isEmpty = (value: string | string[]): boolean => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return value === '' || value == null;
};
