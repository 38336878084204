// eslint-disable-next-line @typescript-eslint/naming-convention
export type allPlan = {
  _id: string;
  originalPrice: number;
  trialDays: null;
  noOfFreeInvoices: number;
  priceAfterFreeInvoice: null;
  isYearlyPlan: boolean;
  yearlyPlanFor: null;
  planDescription: string;
  planId: string;
  planName: string;
  price: number;
  __v: number;
  offeredDiscountedPrice: null;
  isBestValue: boolean;
};

export type GlobalPlan = {
  _id: string;
  originalPrice: number;
  trialDays: any;
  noOfFreeInvoices: number;
  priceAfterFreeInvoice: any;
  isYearlyPlan: boolean;
  yearlyPlanFor: any;
  planDescription: string;
  planId: string;
  planName: string;
  price: number;
  __v: number;

  offeredDiscountedPrice: any;
};

export type LanguageData = {
  _id: '5ef9b8e6a51e81506d9b36d3';
  direction: 'ltr';
  code: 'bn';
  name: 'Bengali';
  popularity: 1;
  __v: 0;
};

export type OrderDataItem = {
  orderNum: string;
  shopifyOrderNum: string;
  invoiceDate: string;
  totalAmount: string;
  customerName: string;
  customerEmailId: string;
  orderId: number;
  status: 'Sent' | 'Draft';
  invoiceLink: string;
  isOrderCancelled: boolean;
  isPDFCreated: boolean;
  paymentStatus:
    | 'paid'
    | 'authorized'
    | 'partially_refunded'
    | 'refunded'
    | 'partially_paid'
    | 'pending'
    | 'voided';
  fulfillmentStatus: 'unfulfilled' | 'fulfilled' | 'partial' | 'restocked';
};

export type FetchInvoicesRes = {
  orderData: Array<OrderDataItem>;
  metadata: [
    {
      page: number;
      hasNext: boolean;
    },
  ];
};

export type FulfillmentDataItem = {
  name: string;
  _id?: string;
  fulfillmentJSON: Record<string, any>;
  link?: string;
  pdfGenerated: boolean;
};

export type FetchFulfillmentsRes = { fulfillments: Array<FulfillmentDataItem> };
export const invoicesColumnNames = [
  'orderNo',
  'shopifyOrderNo',
  'invoiceDate',
  'customerName',
  'total',
  'paymentStatus',
  'fulfillmentStatus',
  'status',
] as const;

export const fulfillmentsColumnNames = [
  'orderNo',
  'shopifyOrderNo',
  'createdAt',
  'fulfillmentStatus',
  'status',
] as const;
export type FetchInvoiceQueryParams = {
  OrderBy: 'asc' | 'desc';
  sortBy: (typeof invoicesColumnNames)[number];
  limit: 25 | 50 | 75 | 100;
  searchText?: string;
  pageNo: number;
  paymentStatus?: OrderDataItem['paymentStatus'][];
  fulfillmentStatus?: OrderDataItem['fulfillmentStatus'][];
  mail?: OrderDataItem['status'];
  orderStatus?: 'Open' | 'Cancelled';
  startDate?: Date;
  endDate?: Date;
};

export const initialInvoicesQueryParams: FetchInvoiceQueryParams = {
  sortBy: 'invoiceDate',
  OrderBy: 'desc',
  pageNo: 1,
  searchText: '',
  limit: 25,
};

export const initialFulfillmentsQueryParams = {
  sortBy: 'createdAt',
  sortOrder: 'desc',
  page: 1,
  limit: 25,
  searchText: '',
};
export interface IFulfillment {
  storeName: string;
  orderId: number;
  orderNumber: string;
  fulfillmentNumber: string;
  pdfGenerated: boolean;
  link?: string;
  fulfillmentJSON: any;
  createdAt: Date;
  updatedAt: Date;
  mailStatus: 'sent' | 'draft';
}

export const sortableColumns = [
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  false,
];
