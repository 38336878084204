import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import {
  fetchAllPlans,
  fetchAnalytics,
  fetchCustomPricingPlans,
  fetchDefaultStringData,
  fetchEmailSettings,
  fetchFontFamiliesData,
  fetchFulfillments,
  fetchGlobalPlans,
  fetchInvoices,
  fetchLanguageData,
  fetchOrderFulfillments,
  fetchSettings,
  fetchShopData,
  fetchTemplateData,
} from './api.service';
import {
  FetchFulfillmentsRes,
  FetchInvoiceQueryParams,
  FetchInvoicesRes,
  FulfillmentDataItem,
} from '../types/api.types';

export const useSettingsQueryTags = ['settings'];
export const useShopDataQueryTags = ['shopData'];
export const useAllPlansQueryTags = ['allPlans'];
export const useGlobalPlansQueryTags = ['globalPlans'];
export const useEmailSettingsQueryTags = ['email-settings'];
export const useLanguageQueryTags = ['languageData'];
export const useDefaultStringDataQueryTags = ['default'];
export const useFontFamiliesQueryTags = ['fontFamilies'];
export const useTemplateDataQueryTags = ['template'];
export const useInvoicesQueryTags = ['invoices'];
export const useOrderFulfillmentsQueryTags = ['fulfillments'];
export const useSettingsQuery = (
  options?: Omit<
    UseQueryOptions<any, any, any, string[]>,
    'queryKey' | 'queryFn'
  >,
) => useQuery(useSettingsQueryTags, () => fetchSettings(), options);

export const useShopDataQuery = () =>
  useQuery(useShopDataQueryTags, () => fetchShopData());

// use: pricing page
export const useAllPlansQuery = () =>
  useQuery(useAllPlansQueryTags, () => fetchAllPlans());

//  use:pricingOfferPage
export const useGlobalPlansQuery = () =>
  useQuery(useGlobalPlansQueryTags, () => fetchGlobalPlans());

export const useEmailSettingsQuery = () =>
  useQuery(useEmailSettingsQueryTags, () => fetchEmailSettings());
// use: onboarding step2
export const useLanguagesDataQuery = () =>
  useQuery(useLanguageQueryTags, () => fetchLanguageData());

export const useTemplateDataQuery = () =>
  useQuery(useTemplateDataQueryTags, () => fetchTemplateData());
export const useFontFamiliesDataQuery = () =>
  useQuery(useFontFamiliesQueryTags, () => fetchFontFamiliesData());
export const useSelectedLanguageStringDataQuery = (
  selectedLangCode: string,
) => {
  const [isChangedFirstTime, setIsChangedFirstTime] = useState(false);

  const queryResult = useQuery(
    [...useDefaultStringDataQueryTags, selectedLangCode],
    () => fetchDefaultStringData(selectedLangCode),
    {
      enabled: !!selectedLangCode,
      suspense: !isChangedFirstTime,
      keepPreviousData: true,
    },
  );

  const { isSuccess } = queryResult;

  useEffect(() => {
    setIsChangedFirstTime(true);
  }, [isSuccess]);

  return queryResult;
};
// use: pricing customPricing
const useCustomPricingPlansQueryTags = ['customPricingPlans'];

export const useCustomPricingPlansQuery = () =>
  useQuery(useCustomPricingPlansQueryTags, () => fetchCustomPricingPlans());

// export const useAllPlansQuery = () => {
//   const appInstance = useAppBridge();
//   const data = useQuery(useAllPlansQueryTags, () => fetchAllPlans(appInstance));
//   return data;
// };
export const useInvoicesDataQuery = (parameters: FetchInvoiceQueryParams) => {
  const debouncedParams = useDebounce(parameters, 500);
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [...useInvoicesQueryTags, debouncedParams],
    [debouncedParams],
  );
  const { isFetching, isSuccess, data, error } = useQuery<FetchInvoicesRes>({
    queryKey,
    queryFn: ({ signal }) =>
      fetchInvoices(
        {
          ...debouncedParams,
          searchText: debouncedParams.searchText
            ?.trim()
            .replace(/[\\^$.*+?()[\]{}|]/g, '\\$&'),
        },
        signal,
      ),
    keepPreviousData: true,
  });

  if (!isSuccess) {
    throw error;
  }

  const setOrderData = (updatedOrderData: FetchInvoicesRes['orderData']) => {
    const currQueryData = queryClient.getQueryData<FetchInvoicesRes>(queryKey);
    if (!currQueryData) return;
    queryClient.setQueryData<FetchInvoicesRes>(queryKey, {
      ...currQueryData,
      orderData: updatedOrderData,
    });
  };
  return [data, isFetching, setOrderData] as const;
};

export const useFulfillmentsDataQuery = (params: any) => {
  const debouncedParams = useDebounce(params, 500);
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [...useOrderFulfillmentsQueryTags, debouncedParams],
    [debouncedParams],
  );
  const { isSuccess, isFetching, data, error } = useQuery({
    queryKey,
    queryFn: () => fetchFulfillments(params),
    keepPreviousData: true,
  });
  if (!isSuccess) {
    throw error;
  }
  const setFulfillmentData = (updatedFulfillmentData: any) => {
    const currQueryData = queryClient.getQueryData(queryKey);
    if (!currQueryData) return;
    queryClient.setQueryData(queryKey, {
      ...currQueryData,
      fulfillments: updatedFulfillmentData,
    });
  };
  return [data, isFetching, setFulfillmentData] as const;
};

function sortByCreatedAtDescending(
  data: FulfillmentDataItem[],
): FulfillmentDataItem[] {
  return data.sort((a, b) => {
    const dateA = new Date(a.fulfillmentJSON.created_at).getTime();
    const dateB = new Date(b.fulfillmentJSON.created_at).getTime();
    return dateB - dateA;
  });
}
export const useFetchOrderFulfillmentDataQuery = (orderId: string) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [...useOrderFulfillmentsQueryTags, orderId],
    [orderId],
  );
  const { isFetching, data, error, isSuccess } = useQuery<FetchFulfillmentsRes>(
    queryKey,
    () => fetchOrderFulfillments(orderId),
    {
      keepPreviousData: true,
      refetchOnMount: 'always',
    },
  );
  if (!isSuccess) throw error;
  const setFulfillmentData = (updatedFulfillmentData: any) => {
    const currQueryData = queryClient.getQueryData(queryKey);
    if (!currQueryData) return;
    queryClient.setQueryData(queryKey, {
      ...currQueryData,
      fulfillmentData: updatedFulfillmentData,
    });
  };
  return [
    sortByCreatedAtDescending(data.fulfillments),
    isFetching,
    setFulfillmentData,
  ] as const;
};

export const useAnalyticsQuery = () =>
  useQuery(['analytics'], () => fetchAnalytics(), {
    suspense: false,
  });
