import type webVitalTypes from 'web-vitals';

const reportWebVitalsWithImports = (
  { getCLS, getFID, getFCP, getLCP, getTTFB }: typeof webVitalTypes,
  onPerfEntry?: webVitalTypes.ReportHandler,
) => {
  if (onPerfEntry) {
    // eslint-disable-next-line global-require
    // import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getFCP(onPerfEntry);
    // @ts-ignore
    getLCP(perfEntry => {
      perfEntry.entries.forEach((entry: any) => {
        console.log(entry.element?.outerHTML);
      });
      onPerfEntry?.(perfEntry);
    });
    getTTFB(onPerfEntry);
    // });
  }
};

export default function reportWebVitals(
  onPerfEntry?: webVitalTypes.ReportHandler,
) {
  if (process.env.REACT_APP_ENV !== 'local') {
    // eslint-disable-next-line global-require
    reportWebVitalsWithImports(require('web-vitals'), onPerfEntry);
  } else {
    import('web-vitals').then(webVitals => {
      reportWebVitalsWithImports(webVitals, onPerfEntry);
    });
  }
}
