import { useState, useEffect } from 'react';
import * as Redirect from '@shopify/app-bridge/actions/Navigation/Redirect';
import * as ToastAction from '@shopify/app-bridge/actions/Toast';
import { AxiosError } from 'axios';
import { Button } from '@shopify/polaris';
import { isMobile } from '@shopify/app-bridge/utilities/platform';
import getApp from '../../services/app-bridge.service';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';

function ViewInvoiceForDraftOrder() {
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState<null | { link: string }>(null);
  const [err, setErr] = useState<null | AxiosError<{ message: string }>>(null);

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);

    const draftOrderId = pageSearchParams.get('id');
    httpInterceptor
      .get('/api/order/draft/view', {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          id: draftOrderId,
          shop: pageSearchParams.get('shop'),
        },
      })
      .then(axiosRes => {
        setRes(axiosRes.data);
        const app = getApp()!;
        const redirect = Redirect.create(app);
        redirect.subscribe(Redirect.Action.REMOTE, () => {
          if (!isMobile()) {
            redirect.dispatch(
              Redirect.Action.ADMIN_PATH,
              `/draft_orders/${draftOrderId}`,
            );
          }
        });
        redirect.dispatch(Redirect.Action.REMOTE, {
          newContext: true,
          url: axiosRes.data.link as string,
        });
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <CommonScreen loading />;
  }

  if (
    typeof res !== 'object' ||
    (err && (!err.response || err.response.status !== 400))
  ) {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message: 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    return <CommonScreen loading={false} />;
  }

  if (err) {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message: err.response!.data!.message,
      isError: true,
      duration: 5000,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);

    return (
      <CommonScreen loading={false}>
        <Button
          onClick={() => {
            const redirect = Redirect.create(app);
            redirect.dispatch(Redirect.Action.APP, '/pricing');
          }}
        >
          Upgrade to paid plan
        </Button>
      </CommonScreen>
    );
  }

  return <CommonScreen loading={false} />;
}

export default ViewInvoiceForDraftOrder;
