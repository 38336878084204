import { useState, useEffect } from 'react';
import { isMobile } from '@shopify/app-bridge/utilities/platform';
import * as ToastAction from '@shopify/app-bridge/actions/Toast';
import { AxiosError } from 'axios';
import * as Redirect from '@shopify/app-bridge/actions/Navigation/Redirect';
import getApp from '../../services/app-bridge.service';
import { httpInterceptor } from '../../services/api.service';
import CommonScreen from '../components/CommonScreen';

function ViewInvoiceForOrder() {
  const [loading, setLoading] = useState(true);
  const [res, setRes] = useState<null | string | { link: string }>(null);
  const [err, setErr] = useState<null | AxiosError>(null);

  useEffect(() => {
    const pageSearchParams = new URLSearchParams(window.location.search);

    const orderId = pageSearchParams.get('id')!;
    httpInterceptor
      .get('/shopify/generateInvoice/getLink', {
        headers: {
          'un-authenticated': 'true',
        },
        params: {
          id: orderId,
          shop: pageSearchParams.get('shop'),
        },
      })
      .then(axiosRes => {
        setRes(axiosRes.data);
        if (axiosRes.data.link) {
          const app = getApp()!;
          const redirect = Redirect.create(app);
          redirect.subscribe(Redirect.Action.REMOTE, () => {
            if (!isMobile()) {
              redirect.dispatch(
                Redirect.Action.ADMIN_PATH,
                `/orders/${orderId}`,
              );
            }
          });
          const invoiceLink = new URL(axiosRes.data.link);
          invoiceLink.searchParams.append('timestamp', Date.now().toString());

          redirect.dispatch(Redirect.Action.REMOTE, {
            newContext: true,
            url: invoiceLink.toString(),
          });
        }
      })
      .catch(axiosErr => {
        console.error(axiosErr);
        return setErr(axiosErr);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <CommonScreen loading />;
  }

  if (err) {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message:
        err.response?.status === 400 &&
        (err.response?.data as any | undefined)?.code === 'PLAN_LIMIT_EXCEEDED'
          ? (err.response.data as any).message
          : 'Something went wrong',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    return <CommonScreen loading={false} />;
  }

  if (typeof res === 'string') {
    const app = getApp()!;
    const toastAction = ToastAction.create(app, {
      message:
        'Your order has not proceed yet. Please try again after some time.',
      duration: 5000,
      isError: true,
    });
    toastAction.dispatch(ToastAction.Action.SHOW);
    return <CommonScreen loading={false} />;
  }

  return <CommonScreen loading={false} />;
}

export default ViewInvoiceForOrder;
