import { BlockStack, Button, Image } from '@shopify/polaris';
import { PropsWithChildren } from 'react';
import * as Redirect from '@shopify/app-bridge/actions/Navigation/Redirect';
import getApp from '../../services/app-bridge.service';

export default function CommonScreen({
  loading,
  children,
}: PropsWithChildren<{
  loading: boolean;
}>) {
  return (
    <BlockStack inlineAlign='center' align='center' gap='500'>
      <Image
        width={80}
        height={80}
        source='/images/app-icon.png'
        alt='Invoice Hero'
      />
      {children || (
        <Button
          loading={loading}
          onClick={() => {
            const app = getApp()!;
            const redirect = Redirect.create(app);
            redirect.dispatch(Redirect.Action.APP, '/');
          }}
          variant='primary'
        >
          Continue to app
        </Button>
      )}
    </BlockStack>
  );
}
