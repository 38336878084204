import { lazy, Suspense } from 'react';
import LoadingCom from '../../../components/LoadingCom';
import PageErrorBoundary from '../../../components/PageErrorBoundary';

const Step1 = lazy(() => import('./Index'));

export default function SuspendedStep1SetupPage(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <Step1 {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}
