import { useNavigate } from '@shopify/app-bridge-react';
import type { PageProps } from '@shopify/polaris';

export default function useHelpAndSupportAction(): PageProps['primaryAction'] {
  const navigate = useNavigate();

  return {
    content: 'Help & Support',
    onAction() {
      navigate('https://calendly.com/mlveda-team', { target: 'new' });
    },
  };
}
