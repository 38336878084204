import {
  SkeletonPage,
  Layout,
  LegacyCard,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';

export const Loading = () => (
  <SkeletonPage primaryAction>
    <Layout>
      <Layout.Section>
        <LegacyCard sectioned>
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SkeletonDisplayText />
          </LegacyCard.Section>
        </LegacyCard>
        <LegacyCard sectioned>
          <LegacyCard.Section>
            <SkeletonBodyText />
          </LegacyCard.Section>
          <LegacyCard.Section>
            <SkeletonDisplayText />
          </LegacyCard.Section>
        </LegacyCard>
      </Layout.Section>
    </Layout>
  </SkeletonPage>
);
