import { lazy, Suspense } from 'react';
import { Loading } from '../../components/Loading';
import PageErrorBoundary from '../../components/PageErrorBoundary';

export const fetchSettingsPage = () =>
  import(/* webpackPrefetch: true */ './index');

const Settings = lazy(fetchSettingsPage);

export default function SuspendedSettings(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Settings {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}
