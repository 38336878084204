import { lazy, Suspense } from 'react';
import LoadingCom from '../../components/LoadingCom';
import PageErrorBoundary from '../../components/PageErrorBoundary';

const Billing = lazy(() => import(/* webpackPrefetch: true */ './index'));

export default function SuspendedBillingSettingsPage(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<LoadingCom />}>
        <Billing {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}
